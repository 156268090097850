import React from "react";

function Logoutpage() {

	localStorage.clear();
  window.location.href='/';		
  
  return (
     <></>
  );
}

export default Logoutpage;
